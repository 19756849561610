import React, { useState, useEffect } from "react";
import "../assets/css/index.css";
import isologo from '../assets/vector/isologo.svg';
import coin from '../assets/vector/coin.svg';
import Icons from '../assets/vector/icons';
import Button from '../components/elements/button';
import background from "../assets/img/background.webp";
import { useWeb3React } from '@web3-react/core'
import { injected } from "../utils/connectors";
import contractConnect from "../utils/contractStore"
import { Helmet } from "react-helmet";
import preview from "../images/preview.png"
const Index = () => {


    const { library, activate, account, chainId, active, networkId, error: errortest } = useWeb3React()
    const [contract, setContract] = useState(null)
    const [allowance, setAllowance] = useState(null)
    const [input, setInput] = useState("")
    const [error, setError] = useState(false)
    const [maximumPeach, setMaximumPeach] = useState(null)
    const [remainingAllowance, setRemainigAllowance] = useState(null)
    const [purcharse, setPurcharse] = useState(null)
    const [endingPoint, setEndingPoint] = useState(null)
    const [startingPoint, setStartingPoint] = useState(null)
    const [timeLeft, setTimeLeft] = useState(null)
    const [timeLeftStarting, setTimeLeftStarting] = useState(null)

    const addressContract = "0x499D0f3A0B9EcaCfC2b2449e612c9F8945A7e565"
    const addressContractBusd = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    /*     const addressContract = "0x81dd20c0249CfFE12954AF4FF3241a1dabc8C67F"
        const addressContractBusd = "0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7" */

    useEffect(() => {
        let petition = async () => {

            try {
                await activate(injected)

            } catch (error) {

            }
        }
        petition()
    }, [])




    let getDataToken = (contract, account) => {
        contract.methods.getAllowance().call({ from: account }).then(result => setMaximumPeach(parseInt(result) / parseInt(1 + '0'.repeat(18))))

        contract.methods.getRemainingAllowance().call({ from: account }).then(result => {

            let xValue = 540000 - (parseInt(result) / parseInt(1 + '0'.repeat(18)))

            let remainder = xValue > 530000 ? 0 : (530000 - xValue)


            setRemainigAllowance(remainder)

        })
        contract.methods.getPurchase(account).call({ from: account }).then(result => setPurcharse(parseInt(result) / parseInt(1 + '0'.repeat(18))))

        contract.methods.getEndingPoint().call({ from: account }).then(result => setEndingPoint(result))
    }


    useEffect(() => {

        let intervalId;

        let getContract = async () => {

            let contract = await contractConnect(addressContract, library, "peach")
            let contractBusd = await contractConnect(addressContractBusd, library, "busd")

            contractBusd.methods.allowance(account, addressContract).call({ from: account }).then(result => parseInt(result) > 0 && setAllowance(true))


            getDataToken(contract, account)
            intervalId = setInterval(() => { getDataToken(contract, account) }, 1000)


            contract.methods.getStartingPoint().call({ from: account }).then(result => { setStartingPoint(result) })
            contract.methods.getEndingPoint().call({ from: account }).then(result => setEndingPoint(result))

            setContract({ contract, contractBusd })



        }
        if (account && chainId === 56 && !errortest) getContract()
        if (account) getContract()


        return () => { clearInterval(intervalId) }

    }, [account, chainId, library])



    useEffect(() => {

        let petitions = async () => {


            if ((chainId && chainId !== 56) || errortest) {


                try {

                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: `0x38`,
                                chainName: "Binance Smart Chain",
                                nativeCurrency: {
                                    name: "Binance Coin",
                                    symbol: "BNB",
                                    decimals: 18
                                },
                                rpcUrls: ['https://bsc-dataseed.binance.org/'],
                                blockExplorerUrls: ['https://bscscan.com']
                            }
                        ]
                    });
                } catch (error) {
                    console.log(error)
                }
            }
        }
        petitions()
    }, [chainId, errortest])

    const getTimeLeft = (seconds) => {


        var numdays = Math.floor(seconds / 86400);
        var numhours = Math.floor((seconds % 86400) / 3600);
        var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
        var numseconds = ((seconds % 86400) % 3600) % 60;

        return numdays + " days " + numhours + "h " + numminutes + "m " + numseconds.toFixed(0) + "s";
    }


    useEffect(() => {

        let intervalId;
        let intervalId2;
        if (endingPoint) {

            let timeNow = new Date()
            let secondsLeft = endingPoint - (timeNow.getTime() / 1000)


            if (secondsLeft > 0) {
                intervalId = setInterval(() => {

                    if (secondsLeft > 0) {
                        secondsLeft = secondsLeft - 1;

                        setTimeLeft(getTimeLeft(secondsLeft))
                    }

                }, 1000)
                setTimeLeft(getTimeLeft(secondsLeft))
            } else setTimeLeft(null)


        }
        if (startingPoint) {

            let timeNow = new Date()
            let secondsLeft = startingPoint - (timeNow.getTime() / 1000)
            if (secondsLeft > 0) {
                intervalId2 = setInterval(() => {

                    if (secondsLeft > 0) {
                        secondsLeft = secondsLeft - 1;

                        setTimeLeftStarting(getTimeLeft(secondsLeft))
                    }
                    else {
                        setTimeLeftStarting(null)
                    }

                }, 1000)
                setTimeLeftStarting(getTimeLeft(secondsLeft))
            } else { setTimeLeftStarting(null) }


        }

        return () => { clearInterval(intervalId); clearInterval(intervalId2) }


    }, [endingPoint, startingPoint])

    const onSubmitApproveBusd = async () => {

        if (contract && account) {

            contract.contractBusd.methods.approve(addressContract, 2000 + '0'.repeat(18)).send({ from: account }).then(result => {
                setAllowance(true)
            }).catch(err => setAllowance(false))

        }

    }

    const onSubmitBuyPeach = async () => {
        setError(false)

        if (input.trim() === "") return setError("Please Complete Busd field ")
        if (parseInt(input) > maximumPeach) return setError(`Your limit is ${maximumPeach} PEACH`)
        if (purcharse < 0 && parseInt(input) < 125) return setError(`Your minimun limit is 125 PEACH`)
        if (timeLeftStarting) return setError(`IDO has not started`)
        if (!timeLeft) return setError(`IDO is over`)

        if (contract && account) contract.contract.methods.makeIDOSale(parseInt(input) + '0'.repeat(18)).send({ from: account, value: 1 + '0'.repeat(15) }).then((result) => { setInput("") }).catch(err => setError("Transaction Failed"))



    }




    return (
        <>
            <Helmet defer={false}>
                <html lang="en" amp />
                <title>Darling Waifu</title>
                <meta name="description" content="Darling Waifu - IDO" />
                <meta name="theme-color" content="#F0C1DC" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:title" content="Darling Waifu" />
                <meta property="og:type" content="game" />
                <meta property="og:url" content="https://presale.darlingwaifu.com" />
                <meta property="og:image" content={preview} />
                <meta property="og:description" content="Darling Waifu - IDO" />
            </Helmet>
            <img src={background} className={`fixed inset-0 w-full h-full bg-cover  `} alt="background" />
            <div className="m-5 lg:m-20 relative z-[50]">

                {/*  <div className="fixed top-0 bottom-0 left-0 flex w-8 justify-center items-center z-50">
                    <div className="-rotate-90 flex">
                        <div className="text-xl font-semibold whitespace-nowrap cursor-pointer">Contract audit</div>
                        <div className="text-xl font-semibold mx-5">|</div>
                        <div className="text-xl font-semibold whitespace-nowrap cursor-pointer">Contract address</div>
                    </div>
                </div> */}
                <div className="h-28 lg:h-0">
                    <div className="fixed top-4 left-4 right-0 z-[100]">
                        <div className="relative flex justify-center lg:justify-start">
                            <img src={isologo} alt="DarlingWaifu" className="w-72" />
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="text-center">
                        <div className="flex justify-center items-center text-pinkDark text-4xl font-semibold">
                            <div>IDO Sale</div> <img src={coin} />
                        </div>
                        {errortest ?
                            <div className="text-3xl text-indigo font-semibold">
                                <h3> Please switch to binance smart chain mainnet </h3>
                            </div>
                            :
                            <div className="text-3xl text-indigo font-semibold">
                                {timeLeft ? <h3>{(maximumPeach > 0 || purcharse > 0) && "You are on the Whitelist."} {timeLeftStarting ? "Sale starts" : "Public sale ends"} in:</h3> : <h3> Please, connect your wallet. </h3>}
                            </div>
                        }

                        {!errortest && <div className="text-4xl text-indigo font-bold mt-4">{timeLeftStarting || timeLeft}</div>}
                    </div>




                    {!errortest && <div className={`grid grid-cols-1  ${(maximumPeach && timeLeft && remainingAllowance > 0 && !timeLeftStarting) ? "lg:grid-cols-2" : "lg:grid-cols-1"} gap-12 mt-9`}>
                        <div className="px-5 lg:px-16">
                            <div className="bg-pinkSemiDark p-12 rounded-2xl shadow-xl">
                                <div className="text-3xl text-pinkDark font-bold mb-4 text-center">About the IDO</div>
                                <div className="text-2xl font-semibold text-indigo">
                                    <ul className="list-disc mb-8">
                                        <li>Maximum purchase:  {maximumPeach} PEACH</li>
                                        <li>Minimum purchase:  {purcharse <= 0 ? "125" : "0"} PEACH</li>
                                        <li>TGE 15% then progressive accelerated vesting for 4 months</li>
                                        {/*  <li> <strong>YOU CAN ONLY BUY ONCE</strong> </li> */}
                                        <li>Price: 0.8 BUSD</li>
                                    </ul>
                                    <ul className="list-disc">
                                        <li>You have bought {purcharse} PEACH</li>
                                    </ul>
                                </div>
                                <div className="relative mt-8">
                                    <div className=" text-center w-full text-3xl leading-none text-white font-bold">{(100 - (((remainingAllowance) * 100) / 530000)).toFixed(0)}%</div>
                                    <div class="w-full h-8 bg-white rounded-full">
                                        <div class="h-8 bg-pinkDark rounded-full" style={{ width: `${100 - (((remainingAllowance) * 100) / 530000)}%` }}>
                                        </div>
                                    </div>
                                    <div className="text-3xl text-pinkDark font-semibold text-center mt-4">
                                        Tokens Available {parseInt(remainingAllowance).toLocaleString()} / 530,000
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(maximumPeach && timeLeft && remainingAllowance > 0 && !timeLeftStarting) ? <div className="bg-pinkSemiDark p-12 rounded-2xl shadow-xl">
                            <div className="px-5 lg:px-24">
                                <div className="text-3xl text-pinkDark font-bold mb-4 text-center">BUY $PEACH</div>
                                <div className="form">
                                    {/* <div className="flex font-semibold">
                                        <span className="mr-16">MIN</span>
                                        <span>MAX</span>
                                    </div> */}

                                    <div className="flex justify-between items-center bg-white rounded-lg p-2">
                                        <input disabled value={(input * 0.80).toFixed(2)} type="text" className="w-full font-semibold outline-none" />
                                        <div className="font-semibold">BUSD</div>
                                    </div>
                                    <div className="my-5 flex justify-center">
                                        <Icons.arrow_down />
                                    </div>
                                    <div className="flex justify-between items-center bg-white rounded-lg p-2">
                                        <input placeholder="0" onChange={(e) => { setInput(e.target.value) }} min="0" value={input} type="number" className="w-full font-semibold outline-none" />
                                        <div className="font-semibold">PEACH</div>
                                    </div>

                                    <div className="flex justify-center flex-wrap items-center flex-col lg:flex-row">
                                        {!allowance ? <div className="mx-2 my-8">
                                            <Button onSubmit={onSubmitApproveBusd}>APPROVE</Button>
                                        </div> : <div className="mx-2 my-8">
                                            <Button onSubmit={onSubmitBuyPeach}>BUY PEACH</Button>
                                        </div>

                                        }


                                    </div>
                                    {error && <p className=" text-2xl font-bold py-2 px-1  rounded-md mx-5 text-white text-center bg-[#cc2525]"> {error}</p>}
                                </div>
                            </div>

                        </div>
                            : ""}
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Index;
